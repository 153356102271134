import "./css/index.scss"
// import { GoogleSpreadsheet } from 'google-spreadsheet'
//
//
// class Sheet {
// 	constructor() {
// 		this.doc = new GoogleSpreadsheet('15usy08WXP6YTJRpSDHTgO1U-5RtJuS0Xu3SDn42FRPk')
// 	}
//
// 	getSheets() {
// 		this.doc.sheetsByIndex[0]
// 	}
//
// 	init() {
// 		this.doc.useServiceAccountAuth({
// 			client_email: "servicedb-mail-web@db-mail-web.iam.gserviceaccount.com",
// 			private_key: "-----BEGIN PRIVATE KEY-----\nMIIEvQIBADANBgkqhkiG9w0BAQEFAASCBKcwggSjAgEAAoIBAQDfpgZP2HVxPgTg\nX1JkhGsLQl0pXlDqeP+ZDPMQdIcd4yrnUCMshZjEg6wAGdoaqAL06b2WXV0HrRUP\n9VzAbCEBjHspZHEZjpHrCXk0LZQY8Coi/3X1aMn0EsOu1MD0PaiM3L561nh+fzRz\nRY31XoEdH+OR0+xAlpFrdwr1fjpgVG4rQnZSIi9nYqyz5jprTI49Ix+YR6r/o9Ri\nyE9WAwIxyfkeGznSubkeMBmj22ewioVJfOPk5xUiLLzGvpIb1BotbW/HB7dVH89E\no1L1bAOgqLY8kGzLMeva1TPrsvf9FUesuZiE+jgX+iB2tKZaWlvPNg0UXIArMOat\nzI3OXjk9AgMBAAECggEABh279/SHN67Wws0Qi4Osir0In7A+3b6r3MNkJUvOgyR6\ngKNqJtZLeHpSWplaY9a65/g5cVKB0Astc4eF/xQl7e0p1Ba1WhnSRz9101W4wlrV\nCCp8zxaLyVM3C0GxuiwyfxGtWt4hIvJ3HHznNjHcrY948Xy+bgDAHtR9Vfva/FXd\nqmlf3nNIZG/xjx2OWNCkgBPWi/sFjDMddytm1ZOaHfWxUfUebOj+LTYhCGu/8AsK\nY1qc1dbAnC/QRpJH4KbwzIaoVazMbJYTwAEbLcoV0Q8e2CXx4bIqwfdmgzAkaLTv\nvYnIr1PB7Ih8CfWER2/96cBQCn9Q0tHy1W5l34O/oQKBgQD26R3kxd7pZ5griFOo\nxDWxxi33GF6YbEeMKcqe3ESzbAnx9Vi0BrX03pjxoIwE6bGMBFsRf2zvXGHipw0M\nARS5MWDs61qB7UaWE29un8YxhsXgR/LUiLxVM4C03ePkbKeGOrXd2NrQ16LNeBjQ\ni9eIH5cpbXGPo5ALExaNM+TBXQKBgQDn4a+wCHAllFRXz3Y4nLMLWvLtLTvOltY0\nr0d22AZpsD6c+Vi7ki1kKUOiD2kwK6h0YAVn6w2ZOsBSuwVXQ3JKGYPoQsAVrTIg\nk6x95kS2Q1FerwxcWuCmz5ZrL9p9Uix8kNQGgMehM6REeAxSOXUZpDucgrF5IlqG\nNHEuybx5YQKBgG5oFRbsJ94klZW5svb/Laddn8Yi3oG3wVJ6iYD5q7qzL8tC0zRE\n3+4r7yX0jUfRG7QpanNrkFGD9L09x4a7oQGig93M6763U93duPE1fpE825SLd5jz\nv4s1qXK22aWVtF9AN3yI8Gd8VO8dqbLvJSRYQ+ahaqs5Dzr0gRwIvVx9AoGABx2C\nwZkQr+aETAl+rDAKwm9xAtwfeLBiNE2omLn6QGX7FeAE3ASZRfiKLb/hT2wXiDKm\nF9E7opcN7bv1Zv8X07RCuu7JbCDbZ+qAIYDeXOVl6szeSTs0p4m5KOLYBn5uBQiA\nqQ5skcK+6KbyYdeHg1jpx1yvS3NFadbUHkZsdSECgYEAwFjM3KWMoWHGSy4DXLTs\n58SgTcXsCgbGiAARGCWJuXeTOlPIJJSESc8Ujyl0i+ayDH475tA6G5XahOf6iSon\nLkAAAc3L9yJtPkfi44Vb8RT+DQpdcQLDfU1yTkiynPgnkVXmjfwKzN2qivwUH6Z6\ndCiSME4f+MYbYaUWvIUwT+k=\n-----END PRIVATE KEY-----\n",
// 		})
//
// 		this.doc.loadInfo()
// 	}
// }

const handleShowError = (show) => {
	const error = document.querySelector('.js-quiz-error')
	if (!error) return
	error.style.display = show ? 'block' : 'none'
}

const shouldShowEmail = (state) => {
	return state.first === 1 && state.second === 2
}

const initQuiz = () => {
	// const sheet = new Sheet()
	//
	// sheet.init()

	const state = {
		first: 0,
		second: 0,
	}
	const quiz1Radio1 = document.querySelector('.js-quiz-radio-1-1')
	const quiz1Radio2 = document.querySelector('.js-quiz-radio-1-2')
	const quiz2Radio1 = document.querySelector('.js-quiz-radio-2-1')
	const quiz2Radio2 = document.querySelector('.js-quiz-radio-2-2')
	const shouldContinue = quiz1Radio1 && quiz1Radio2 && quiz2Radio1 && quiz2Radio2

	if (!shouldContinue) return
	quiz1Radio1.addEventListener('change', e => {
		const { value } = e.target

		if (!value) return
		state.first = parseFloat(value)
		handleShowError(false)
	})

	quiz1Radio2.addEventListener('change', e => {
		const { value } = e.target

		if (!value) return
		state.first = parseFloat(value)
		handleShowError(false)
	})

	quiz2Radio1.addEventListener('change', e => {
		const { value } = e.target

		if (!value) return
		state.second = parseFloat(value)
		handleShowError(false)
	})

	quiz2Radio2.addEventListener('change', e => {
		const { value } = e.target

		if (!value) return
		state.second = parseFloat(value)
		handleShowError(false)
	})

	const submit = document.querySelector('.js-quiz-submit')
	const quizSendEmail = document.querySelector('.js-quiz-send-email')
	const quizCode = document.querySelector('.js-quiz-code')
	if (!submit || !quizSendEmail || !quizCode) return

	submit.addEventListener('click', e => {
		if (state.first === 0 || state.second === 0) return
		e.preventDefault()

		if (!shouldShowEmail(state)) {
			handleShowError(true)
			return
		}

		const email = document.querySelector('.js-quiz-email')
		if (!email) return
		email.removeAttribute('style')
	})

	quizSendEmail.addEventListener('click', e => {
		e.preventDefault()
		const email = document.querySelector('.js-quiz-email-input')
		if (!email || email && email.value.length === 0) return

		const { value } = email
		const timestamp = new Date().getTime()
		firebase.database().ref('/').child(`user-${timestamp}`).set({
			email: value,
		})
		quizCode.removeAttribute('style')
		email.value = ''
	})
}


const initClient = () => {
	// gapi.client.init({
	// 	apiKey: 'AIzaSyB3nXEagEGY4NlUc00Nit5pSLH1hbQjoBg',
	// 	clientId: '231777148193-8fp8472tc6h3cu528crhq1d7vtidokbh.apps.googleusercontent.com',
	// 	discoveryDocs: ["https://sheets.googleapis.com/$discovery/rest?version=v4"],
	// 	scope: "https://www.googleapis.com/auth/spreadsheets"
	// })

	// Your web app's Firebase configuration
	var firebaseConfig = {
		apiKey: "AIzaSyCscpv4UeEHFKCMgrMoZ4nY9MS7BTlyMZk",
		authDomain: "quickstart-1590415397521.firebaseapp.com",
		databaseURL: "https://quickstart-1590415397521.firebaseio.com",
		projectId: "quickstart-1590415397521",
		storageBucket: "quickstart-1590415397521.appspot.com",
		messagingSenderId: "231777148193",
		appId: "1:231777148193:web:66579e4934df1a405a7260"
	};
	// Initialize Firebase
	firebase.initializeApp(firebaseConfig);
}

initClient()
initQuiz()

// window.handleClientLoad = () => {
// 	gapi.load('client:auth2', initClient);
// }
